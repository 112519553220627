<template>
  <div class="footer ">
    <div class="minwidth">
      <div class="info-box">
        <div class="single" v-for="item in infoList" :key="item.id">
          <div class="pointer icon-box">
            <svg-icon class-name="icon" :icon-class="item.icon"></svg-icon>
          </div>
          <div class="name">{{ item.name }}</div>
          <p class="subtitle">{{ item.subtitle }}</p>
        </div>
      </div>
      <ul class="btm-box">
        <li>
          <a class="btm" href="https://beian.miit.gov.cn/">豫ICP备2023010622号-1</a>
        </li>
        <li style="margin-left: 8px">
          河南百霸文化传播有限公司
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data(){
    return{
      infoList: [
        { id: 0, icon: 'address', name: '地址', subtitle: '郑州市中原区桐柏南路9号' },
        { id: 1, icon: 'phone', name: '电话', subtitle: '400-100-7180' },
        { id: 2, icon: 'email', name: '邮箱', subtitle: 'Baiba@naomishu.cn' }
      ]
    }
  },
  methods:{

  }
}
</script>

<style scoped>
.footer{
  height: 356PX;
  background: #F7F7F8;
  width: 100%;
}
.info-box{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60PX 0 70PX 0;
}
.single {
  width: 33.33%;
  text-align: center;
}
.icon-box{
  width: 69PX;
  height: 69PX;
  border-radius: 50%;
  background: #EDEDED;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-box:hover{
  background: #FE0303;
}
.icon{
  width: 30PX !important;
  height: 30PX !important;
  color: #A7ACB0;
}
.icon-box:hover .icon{
  color: #fff !important;
}
.single .name{
  font-size: 16PX;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #000000;
  line-height: 35PX;
  margin-top: 13PX;
}
.single .subtitle{
  font-size: 14PX;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #5E6873;
  line-height: 26PX;
}
.btm-box{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14PX;
  font-family: HarmonyOS Sans SC-Medium, HarmonyOS Sans SC;
  font-weight: 500;
  color: #8C8C8C;
  line-height: 16PX;
  margin-bottom: 66PX;
}
.btm{
  color: #8C8C8C;
}
</style>