<template>
  <div id="app" v-if="!isPCorPhone">
    <navigation :activeNum="activeNum" />
    <div>
      <router-view @active="active" />
    </div>
    <!-- <vfooter /> -->
    <newFooter />
   
    <go-top />
  </div>
  <div id="app" v-else>
    <router-view />
  </div>
</template>

<script>
import navigation from "@/components/navigation";
import vfooter from "@/components/footer";
import goTop from "@/components/goTop";
import newFooter from "@/components/newFooter"
export default {
  name: 'App',
  components:{
    vfooter,
    navigation,
    goTop,
    newFooter
  },
  data(){
    return{
      isPCorPhone: '',
      activeNum: 0
    }
  },
  created() {
    this.isPCorPhone = this.isMobileUserAgent()
  },
  methods:{
    isMobileUserAgent(){// 判断是pc端还是h5端
      return /iphone|ipod|android|windows.*phone|blackberry.*mobile/i.test(
        window.navigator.userAgent.toLowerCase());
    },
    active(e){
      this.activeNum = e
    }
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.minwidth{
  width: 1200PX;
  margin: 0 auto;
}
.pointer{
  cursor: pointer;/*鼠标变小手*/
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.ellipsis3 {
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
