import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: () => import('@/pages/home/index.vue'),
        meta:{
            title: '百霸赋能'
        }
    },
    {
        path: '/load',
        component: () => import('@/pages/load/index.vue'),
        meta:{
            title: '百霸赋能'
        }
    },
    {
        path: '/news',
        component: () => import('@/pages/news/index.vue'),
        meta:{
            title: '百霸赋能'
        }
    },
    {
        path: '/details/:id',
        name: 'Details',
        component: () => import('@/pages/news/details.vue'),
        meta:{
            title: '百霸赋能'
        }
    },
    {
        path: '/load',
        component: () => import('@/pages/load/index.vue'),
        meta:{
            title: '百霸赋能'
        }
    },
    // app引导页面
    {
        path: '/h5/lead',
        component: () => import('../h5-lead/index.vue'),
        meta:{
            title: '百霸育儿'
        }
    },
    //产品介绍
    {
        path: '/product',
        component: () => import('../pages/newPages/product.vue'),
        meta:{
            title: '百霸育儿'
        }
    },
    //公司文化
    {
        path: '/culture',
        component: () => import('../pages/newPages/culture.vue'),
        meta:{
            title: '百霸育儿'
        }
    },
    //联系我们
    {
        path: '/contact',
        component: () => import('../pages/newPages/contact.vue'),
        meta:{
            title: '百霸育儿'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
function isMobileUserAgent(){
    return /iphone|ipod|android|windows.*phone|blackberry.*mobile/i.test(
        window.navigator.userAgent.toLowerCase());
}
router.beforeEach((to, form, next) => {
    const isPCorMobile = isMobileUserAgent()
    if (to.meta.title) { // 判断是否有标题
        document.title = to.meta.title;
    } else {
        document.title = '百霸赋能';
    }
    if(!isPCorMobile){
        next()
    } else {
        if(to.path === '/h5/lead'){
            next()
        } else {
            next({ path: '/h5/lead' })
        }
    }
});
export default router
