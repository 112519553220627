<template>
  <div class="back-top pointer" v-show="isShow" @click="goTop">
       回到<br>
       顶部
  </div>
</template>

<script>
export default {
  name: "goTop",
  data(){
    return {
      isShow: false
    }
  },
  methods:{
    goTop(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    handleScroll() {
      this.isShow = window.pageYOffset >100;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>
.back-top{
  width: 60PX;
  height: 60PX;
  position: fixed;
  bottom: 50PX;
  z-index: 100;
  right: 50PX;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14PX;
}
.back-top:hover {
  background: rgba(0,0,0,.1);
  color: #fff;
}
</style>