<template>
    <div class="footer ">
        <div class="footerLeft">
            <div :class="item.id == 1?'footerText':'footerText2'" v-for="(item,index) in arr" :key="index">{{item.name}}</div>
        </div>
        <div class="bottom">网站备案号：豫ICP备18045967号-1Copyright © 2016-2022 Baiba</div>
    </div>
</template>

<script>
    export default {
        name: "newFooter",
        data() {
            return {
                arr: [
                    {
                        id: 1,
                        name: '企业文化'
                    },
                    {
                        id: 1,
                        name: '我们的产品'
                    },
                    {
                        id: 1,
                        name: '最新资讯'
                    },
                    {
                        id: 2,
                        name: '公司介绍'
                    },
                    {
                        id: 2,
                        name: '百霸育儿'
                    },
                    {
                        id: 2,
                        name: '百霸育儿'
                    },
                    {
                        id: 2,
                        name: ''
                    },
                    {
                        id: 2,
                        name: '星源使命计划'
                    },
                    {
                        id: 2,
                        name: '星源使命计划'
                    }
                ]
            }
        },
        methods: {

        }
    }
</script>

<style scoped>
    .footer {
        width: 100%;
        height: 412PX;
        background: #333B43;
        border-radius: 0PX;
        opacity: 1;
        padding: 80PX 0PX 0PX 0PX;
        box-sizing: border-box;
        position: relative;
    }

    .footerLeft {
        display: grid;
        grid-template-columns: repeat(auto-fill, 200PX);
        grid-row-gap: 40PX;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 600PX;
        margin-left: 8%;
    }

    .footerText {
        width: 80PX;
        height: 35PX;
        font-size: 16PX;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 35PX;
        text-align: left;
    }

    .footerText2 {
        width: 84PX;
        height: 26PX;
        font-size: 14PX;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 400;
        color: #D1DAE2;
        line-height: 26PX;
        text-align: left;
    }

    .bottom {
        width: 451PX;
        height: 16PX;
        font-size: 14PX;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16PX;
        position: absolute;
        bottom: 30PX;
        left: 0;
        right: 0;
        margin: auto;
    }
</style>