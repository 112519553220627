<template>
  <div class="navigation">
  <!-- <div class="navigation" :class="activeNum == -1 ? 'new-bg': ''"> -->
    <div class="box minwidth">
      <img class="logo pointer" src="../assets/img/logo.png" alt="logo" @click="toNav({id: 5})">
      <!-- <img class="logo pointer" v-if="activeNum != -1" src="../assets/img/logo.png" alt="logo" @click="toNav({id: 5})"> -->
      <!-- <img class="logo pointer" v-else src="../assets/img/logo-fff.png" alt="logo" @click="toNav({id: 5})"> -->
      <!-- <img class="news-font" v-if="activeNum == -1" src="../assets/img/new-bg-font.png" alt="最新新闻"> -->
      <div class="right-nav">
        <div class="nav pointer" @click="toNav(item)" :class="item.id == activeid ? 'nav-active' : ''" v-for="item in navList" :key="item.id">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topNavigation",
  data(){
    return {
      navList: [
        {id: 0 , name: '公司文化'},
        {id: 1 , name: '产品介绍'},
        {id: 2 , name: '最新资讯'},
        {id: 3 , name: '联系我们'},
      ],
      activeid: null
    }
  },
  props: {
    activeNum: {
      type: Number,
      default: 0
    }
  },
  mounted() {

  },
  methods: {
    toNav(item){
      const obj = {
        5:'/home',
        0: '/culture',
        1:'/product',
        2:'/news',
        3: '/contact',
        
      }
      this.activeid = item.id
      console.log(item,'......')
      this.$router.push(obj[item.id],() => {});
    }
  }
}
</script>

<style scoped>
.navigation {
  width: 100%;
  background: #fff;
}

.box{
  height: 110PX;
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.logo{
  width: 180PX;
  height: 61PX;
  margin-right: 77PX;
}
.right-nav {
  display: flex;
  align-items: center;
}
.nav{
  width: 105PX;
  font-size: 18PX;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #061727;
  margin-right: 44PX;
}
.nav:last-child{
  margin-right: 0;
}
.nav-active {
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
}

.new-bg{
  background: url('~@/assets/img/new-bg.png') no-repeat;
  background-size: 100% 472PX;
  height: 472PX;
}
.new-bg .nav{
  color: #fff;
}
.news-font{
  position: absolute;
  left: 0PX;
  top: 214PX;
  width: 288PX;
  height: 116PX;
}
</style>